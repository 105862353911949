import React from "react"

export default props => (
  <footer style={{ backgroundColor: "#ececec" }}>
    <div
      style={{
        margin: "0 auto",
        maxWidth: 800,
        padding: "1.25rem 1rem"
      }}
    >
      <p>This is where the footer will go.</p>
    </div>
  </footer>
)
